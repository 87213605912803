<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування контактів" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-data-table
                  :fields="fields"
                  :data="contactsData"
                  no-pagination
                >
                  <!-- <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template> -->

                  <!-- <template slot="toggle" slot-scope="props">
                    <va-toggle
                      small
                      v-on:input="togglePublished(props.rowData.id)"
                      v-model="props.rowData.published"
                    />
                  </template> -->

                  <template slot="translations" slot-scope="props">
                    <va-badge color="info" :outline="!translation.status" v-for="(translation, index) in props.rowData.trans" :key="index">{{ translation.locale }}</va-badge>
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'prylukyagro-contacts-edit', params: { id: props.rowData.id } }"
                      :test="props.rowData.id"
                    >
                      Редагувати
                    </va-button>
                  </template>
                </va-data-table>
                <!-- <va-card class="mb-3" title="Contact widget image *">
                  <div v-if="imgExisting">
                    <a style="display: block;" target="_blank" :href="imgExisting"><img style="max-width: 400px;" :src="imgExisting"></a>
                    <div><va-button small color="danger" @click="imgExisting = deleteImage(imgId, 'contacts_image') ? '' : imgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.contacts_image"
                    v-model="img"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.contacts_image" style="color: red;">{{ errors.contacts_image[0] }}</div>
                  <va-button @click="uploadImage('contacts_image', 'contacts_image', img[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Contact widget title *"
                  v-model="title"
                  :messages="['The recommended number of characters is 20']"
                  :error="!!errors.contacts_title"
                  :error-messages="errors.contacts_title"
                  @input="delete errors.contacts_title"
                />
                <va-input
                  label="Contact widget address *"
                  v-model="address"
                  :type="'textarea'"
                  :messages="['The recommended number of characters is 200']"
                  :error="!!errors.contacts_address"
                  :error-messages="errors.contacts_address"
                  @input="delete errors.contacts_address"
                />
                <va-input
                  label="Contact widget phone *"
                  v-model="phone"
                  :error="!!errors.contacts_phone"
                  :error-messages="errors.contacts_phone"
                  @input="delete errors.contacts_phone"
                />
                <va-input
                  label="Contact widget email *"
                  v-model="email"
                  :messages="['The recommended number of characters is 191']"
                  :error="!!errors.contacts_email"
                  :error-messages="errors.contacts_email"
                  @input="delete errors.contacts_email"
                />
                <va-input
                  label="Contact widget file label *"
                  v-model="fileLabel"
                  :messages="['The recommended number of characters is 100']"
                  :error="!!errors.contacts_file_label"
                  :error-messages="errors.contacts_file_label"
                  @input="delete errors.contacts_file_label"
                />
                <va-card class="mb-3" title="Contact widget file">
                  <div v-if="fileExisting">
                    <a class="link" v-if="fileExisting" style="display: block;" target="_blank" :href="fileExisting">{{ fileExisting }}</a>
                    <div><va-button small color="danger" @click="fileExisting = deleteImage(fileId, 'contacts_file') ? '' : fileExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити новий файл</span>
                  <va-file-upload
                    dropzone
                    v-model="file"
                    @input="delete errors.contacts_file"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.contacts_file" style="color: red;">{{ errors.contacts_file[0] }}</div>
                  <va-button @click="uploadImage('contacts_file', 'contacts_file', file[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Contact widget file name *"
                  v-model="fileName"
                  :messages="['The recommended number of characters is 191', 'File name without .pdf']"
                  :error="!!errors.contacts_file_name"
                  :error-messages="errors.contacts_file_name"
                  @input="delete errors.contacts_file_name"
                />
                <va-input
                  label="Contact widget map link"
                  v-model="mapLink"
                  :messages="['The recommended number of characters is 100']"
                  :error="!!errors.contacts_map_link"
                  :error-messages="errors.contacts_map_link"
                  @input="delete errors.contacts_map_link"
                /> -->
                <!-- <div v-for="(contact,index) in contactsData" :key="contact.id" >
                  <va-input
                    :label="contact.label"
                    v-model="contactsData[index].value"
                  />
                </div> -->

                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      // id: '',
      // in_contact: '',
      // in_footer: '',
      // label: '',
      // position: '',
      // value: '',
      // img: [],
      // imgExisting: '',
      // imgId: '',
      // title: '',
      // address: '',
      // phone: '',
      // email: '',
      // fileLabel: '',
      // file: [],
      // fileExisting: '',
      // fileId: '',
      // fileName: '',
      // mapLink: '',
      contactsData: [],

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    // putData () {
    //   return {
    //     contacts_title: this.title,
    //     contacts_address: this.address,
    //     contacts_phone: this.phone,
    //     contacts_email: this.email,
    //     contacts_file_label: this.fileLabel,
    //     contacts_file_name: this.fileName,
    //     contacts_map_link: this.mapLink,
    //   }
    // },
    fields () {
      return [{
        name: 'value',
        title: 'Value',
        width: '55%',
      },
      // {
      //   name: 'position',
      //   title: 'Позиція',
      //   width: '10%',
      // },
      // {
      //   title: 'Опубліковано',
      //   name: '__slot:toggle',
      //   width: '20%',
      // },
      {
        title: 'Переклади',
        name: '__slot:translations',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    // uploadImage (param, key, file) {
    //   if (!file) {
    //     return
    //   }
    //   const formData = new FormData()
    //   formData.append(param, file)
    //   formData.append('lang', this.locale)
    //   formData.append('key', key)
    //   formData.append('multiple', '0')
    //   formData.append('file_id', '0')
    //   axios.post(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/upload/5`, formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   })
    //     .then(response => {
    //       this.showToast('Successful upload')
    //     })
    //     .catch(error => {
    //       console.log(error)
    //       this.showToast('Error')
    //     })
    // },
    // async deleteImage (id, fieldKey) {
    //   return axios.post(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/admin/pages/5/delete-file/${id}`, {
    //     key: fieldKey,
    //   })
    //     .then(() => {
    //       this.showToast('Successfully deleted')
    //     })
    //     .catch(() => {
    //       this.showToast('Error')
    //     })
    // },
    submit () {
      console.log([...this.contactsData])
      axios.put(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/widgets/contacts`, [...this.contactsData])
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      // axios.put(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/admin/pages/validate/5`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
      //   .then(response => {
      //     this.errors = response.data
      //     if (!Object.keys(this.errors).length) {
      //       this.submit()
      //     }
      //   })
      //   .catch(error => {
      //     console.log(error)
      //     this.showToast('Validate Fetch Error')
      //   })
      this.submit()
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/widgets/contacts`)
        .then(response => {
          console.log(response.data.data)

          this.contactsData = response.data.data
          // this.title = response.data.contacts_title
          // this.address = response.data.contacts_address
          // this.phone = response.data.contacts_phone
          // this.email = response.data.contacts_email
          // this.fileLabel = response.data.contacts_file_label
          // this.fileName = response.data.contacts_file_name
          // this.mapLink = response.data.contacts_map_link

          // this.imgExisting = process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.contacts_image
          // this.imgId = response.data.contacts_image_id
          // this.fileExisting = response.data.contacts_file ? process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.contacts_file : null
          // this.fileId = response.data.contacts_file_id
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
